<template>
  <v-card elevation="0" outlined class="card-border">
    <v-card-title>
      <div class="title-h6 font-weight-bold">{{ title }} 배정</div>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="4">
          <v-text-field v-model="search.region" dense label="주소 검색" :readonly="!ersItem" @keyup.enter="searchAgencyByRegion" />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn x-small :disabled="!search.region && !ersItem" color="primary" @click.stop="searchAgencyByRegion">
            검색
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="search.name" dense label="가맹점명 검색" :readonly="!ersItem" @keyup.enter="searchAgencyByName" />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn x-small :disabled="!search.name && !ersItem" color="primary" @click.stop="searchAgencyByName">
            검색
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- agency -->
        <v-col>
          <v-data-table disable-filtering disable-sort single-select dense show-select item-key="_id"
                        :items="agency.items" :headers="agency.headers" :loading="agency.loading" :items-per-page="100"
                        mobile-breakpoint="0" v-model="agency.selected" hide-default-footer height="200px"
          >
            <template v-slot:item.name="{item}">
              <v-row dense>
                <v-col>[{{item.agencyCode}}] {{item.name}}</v-col>
              </v-row>
              <v-row dense v-if="item.remark">
                <v-col><span style="font-size: 0.8em;">{{item.remark}}</span></v-col>
              </v-row>
            </template>
            <template v-slot:item.svc="{ item }">
              <v-tooltip v-if="item.svcAreas" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-map-marker-check</v-icon>
                </template>
                <span>{{item.svcAreas.join(',')}}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row dense>
        <v-spacer />
        <v-col cols="auto">
          <v-btn v-if="agency.selected.length == 1 && ersItem._id" small color="primary" @click="ersRequestAgency">가맹점 배정</v-btn>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="10">
          <v-text-field dense label="메시지" v-model="remark" />
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn x-small @click.stop="updateRemark" :disabled="!agency.selected && !remark" color="info">저장</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- agent -->
        <v-col>
          <v-data-table disable-filtering disable-sort dense
                        :items="agent.items"
                        :headers="agent.headers"
                        :loading="agent.loading"
                        :items-per-page="100"
                        mobile-breakpoint="0" item-key="userId" hide-default-footer height="200px"
                        show-select v-model="agent.selected"
          >
            <template v-slot:item.status="{item}">
              {{vs.renderTaskStatus(item.status)}}
            </template>
            <template v-slot:item.name="{item}">
              <div v-if="item.login && item.login === 'Y'">
                <v-badge bordered dot color="error" offset-x="0" offset-y="5">
                [{{item.userId}}] {{item.name}}
                </v-badge>
              </div>
              <div v-else>[{{item.userId}}] {{item.name}}</div>
              <v-tooltip v-if="item.controller === 'Y'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-eye</v-icon>
                </template>
                <span>관제인</span>
              </v-tooltip>
            </template>
            <template v-slot:item.account="{item}">
              {{item.isLocked ? '잠김' : '정상'}} / {{item.failCount}}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row dense class="d-flex">
        <v-spacer />
        <v-col v-if="agent.selected.length == 1 && ersItem._id">
          <v-btn small @click="ersRequestAgent" color="warning">기사 배정</v-btn>
        </v-col>
        <v-col class="d-flex justify-end" v-if="agent.selected.length == 1 && ersItem._id">
          <v-btn small @click="agentResetPassword" color="error">비밀번호초기화</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col><v-select dense :items="templateOptions" item-text="text" item-value="text" placeholder="템플릿 선택" @change="selectTemplate"></v-select> </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="10"><v-text-field dense label="문자 메시지 입력" v-model="msg.talk"></v-text-field></v-col>
        <v-col cols="12" sm="2"><v-btn x-small :disabled="agent.selected.length < 1 || !msg.talk" color="warning" @click="sendTalk">전송</v-btn></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="10"><v-text-field dense label="메세지 전송 입력" v-model="msg.memo"></v-text-field></v-col>
        <v-col cols="12" sm="2"><v-btn x-small :disabled="agent.selected.length < 1 || !msg.memo" color="warning" @click="sendMemo">전송</v-btn></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
import notify from '@/utils/notify'

export default {
  name: 'ErsWork',
  props: {
    ersItem: { type: Object, default: () => { return {} } },
  },
  data() {
    return {
      vs,
      title: '',
      remark: null,
      msg: {
        talk: null,
        memo: null,
      },
      agency: {
        loading: false,
        items: [],
        selected: [],
        headers: [
          { text: '가맹정 명', value: 'name' },
          { text: '지역', value: 'svc' },
          { text: '대표', value: 'prName' },
          { text: '연락처', value: 'mobileNo' },
        ]
      },
      agent: {
        loading: false,
        items: [],
        selected: [],
        headers: [
          { text: '이름', value: 'name' },
          { text: '상태', value: 'status' },
          { text: '연락처', value: 'phoneNo' },
          { text: '계정', value: 'account' },
        ]
      },
      search: {},
      readOnly: true,
      templateOptions: []
    }
  },
  methods: {
    listTemplateOptions() {
      api.getMessageTemplates('receipt').then(r => {
        this.templateOptions = r.result
        this.templateOptions.splice(0, 0, { text: '' })
      })
    },
    searchAgencyByRegion() {
      this.agency.loading = true
      this.clearForm()
      api.searchAgencyByRegion(this.search.region).then(r => {
        this.agency.items = r.result
      }).finally(() => { this.agency.loading = false })
    },
    searchAgencyByName() {
      this.agency.loading = true
      this.clearForm()
      api.searchAgencyByName(this.search.name).then(r => {
        this.agency.items = r.result
      }).finally(() => { this.agency.loading = false })
    },
    agentByAgencyId(item) {
      this.agency.selected = this.agency.selected.splice(0, this.agency.selected.length)
      this.agency.selected.push(item)
      this.loadAgentByAgency(item._id)
    },
    loadAgentByAgency(agencyId) {
      this.agent.loading = true
      api.agentByAgencyId(agencyId).then(r => {
        this.agent.items = r.result
      }).finally(() => { this.agent.loading = false })
    },
    clearForm() {
      console.log('g - ' + this.agency.selected.length + 't - ' + this.agent.selected.length)
      this.agency.items = []
      // this.agency.selected.splice(0, this.agency.selected.length)
      this.agency.selected = []
      this.agent.items = []
      // this.agent.selected.splice(0, this.agent.selected.length)
      this.agent.selected = []
      this.remark = null
      this.msg = { talk: null, memo: null }
    },
    clearSearch() {
      this.search = {}
    },
    ersRequestAgency() {
      if (this.agency.selected.length === 1) {
        api.ersRequestAgency(this.ersItem._id, this.agency.selected[0]).then(r => {
          notify.showMessage('가맹점 배정 되었습니다.')
          this.clearForm()
          this.$emit('refresh')
        })
      }
    },
    ersRequestAgent() {
      if (this.agent.selected.length === 1) {
        api.ersRequestAgent(this.ersItem._id, this.agent.selected[0]).then(r => {
          notify.showMessage('기사 배정 되었습니다.')
          this.clearForm()
          this.$emit('refresh')
        })
      }
    },
    updateRemark() {
      if (this.agency.selected.length === 1) {
        api.updateAgencyRemark(this.agency.selected[0]._id, this.remark).then(r => {
          notify.showMessage('저장 되었습니다.')
        })
      }
    },
    sendTalk() {
      if (this.agent.selected.length > 0) {
        api.sendMessageKakao(this.agent.selected[0].agencyCode, this.msg.talk, this.agent.selected.map(e => e.userId)).then(r => {
          notify.showMessage('전송 하였습니다.')
          this.msg.talk = ''
        })
      }
    },
    sendMemo() {
      if (this.agent.selected.length > 0) {
        api.sendMemo(this.agent.selected[0].agencyCode, this.msg.memo, this.agent.selected.map(e => e.userId)).then(r => {
          notify.showMessage('전송 하였습니다.')
          this.msg.memo = ''
        })
      }
    },
    selectTemplate(item) {
      this.msg.talk = item
      this.msg.memo = item
    },
    agentResetPassword() {
      if (this.agent.selected.length === 1) {
        const agent = this.agent.selected[0]
        api.initAgentPassword(agent.userId).then((r) => {
          if (r.result.success) {
            notify.showMessage('기사 비밀번호를 초기화하였습니다.')
          } else {
            notify.showError('기사 비밀번호 초기화에 실패 하였습니다. 잠시후 다시 시도해 주십시오.')
          }
        })
      }
    }
  },
  watch: {
    ersItem() {
      this.title = this.ersItem.ersId
      this.clearForm()
      this.clearSearch()
      if (this.ersItem.agency) {
        this.loadAgentByAgency(this.ersItem.agency)
      }
    },
    'agency.selected'() {
      if (this.agency.selected.length > 0) {
        this.loadAgentByAgency(this.agency.selected[0]._id)
      } else {
        this.agent.items = []
      }
    }
  },
  mounted() {
    this.listTemplateOptions()
  }
}
</script>

<style scoped>
.v-btn__content { width: 100%; white-space: normal; }
.title {
  font-weight: bold;
}
.card-border {
  border: 2px solid black;
}
</style>
